* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Campton;
  src: url("../../../../public/Campton-Light.ttf");
}

@font-face {
  font-family: Montserrat;
  src: url("../../../../public/Montserrat-VariableFont_wght.ttf");
}

.change-password {
  padding: 100px 20px 0px 20px;
  width: 81.9%;
  margin-left: 260px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.change-password > .header {
  width: 100%;
  display: flex;
  padding: 20px 0px;
  gap: 15px;
  align-items: center;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.change-password > .header > h3 {
  color: #254a38;
  font-family: Campton;
  font-size: 24px;
  font-weight: 800;
  line-height: 83.523%;
}

.change-password > .header > .icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  color: #254a38;
}

.change-password > form {
  width: 385px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
}

.change-password > form > label {
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.change-password > form > label > span {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 13px 10px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  background: #fff;
}

.change-password > form > label > span > input {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  color: #b5b5b5;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.change-password > form > button {
  display: flex;
  width: 385px;
  height: 50px;
  padding: 10px 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 38px;
  background: #254a38;
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 123.023%;
  border: none;
  outline: none;
  cursor: pointer;
}

.change-password > form > button:disabled {
  background: #7cc1a0;
  cursor: pointer;
}

.change-password > form > .error {
  color: #d0310e;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
}
