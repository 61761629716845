.custom-loader {
    width:25px;
    height:6px;
    background: 
      radial-gradient(circle closest-side at left  3px top 50%,#254A38 90%,#0000),
      radial-gradient(circle closest-side                     ,#254A38 90%,#0000),
      radial-gradient(circle closest-side at right 3px top 50%,#254A38 90%,#0000);
    background-size:100% 100%;
    background-repeat: no-repeat;
    animation:d4 0.5s infinite alternate;
  }
  @keyframes d4 {
      to{width:10px;height:12px}
  }