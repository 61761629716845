* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Campton;
  src: url("../../../../public/Campton-Light.ttf");
}

@font-face {
  font-family: Montserrat;
  src: url("../../../../public/Montserrat-VariableFont_wght.ttf");
}

.news-letter-con {
  padding: 100px 20px 0px 20px;
  width: 81.9%;
  margin-left: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.news-letter-con > .header {
  width: 100%;
  display: flex;
  padding: 20px 0px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.news-letter-con > .header > h3 {
  color: #254a38;
  font-family: Campton;
  font-size: 24px;
  font-weight: 800;
  line-height: 83.523%;
}

.news-letter-con > .header > .in {
  display: flex;
  gap: 10px;
}

.news-letter-con > .header > .in > button:nth-child(1) {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: pointer;
  outline: none;
  color: #344054;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.news-letter-con > .header > .in > button:nth-child(2) {
  display: flex;
  padding: 10px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #254a38;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  border: none;
}

.create-newsletter {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0%;
  left: 0%;
  background: rgba(32, 32, 32, 0.7);
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 101;
  padding: 40px;
  overflow: scroll;
}

.create-newsletter ::-webkit-scrollbar {
  display: none;
}


.create-newsletter  {
  -ms-overflow-style: none;  
  scrollbar-width: none;  
}

.create-newsletter > .card {
  margin-top: 100px;
  width: 850px;
  background: #fff;
  flex-direction: column;
}

.create-newsletter > .card > .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  border-bottom: 1px solid #e0e0e0;
  background: #fbfbfb;
}

.create-newsletter > .card > .header > h3 {
  color: #000;
  font-family: Campton;
  font-size: 14px;
  font-weight: 500;
}

.create-newsletter > .card > .header > .icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.create-newsletter > .card > form {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
}

.create-newsletter > .card > form > .wrap > input {
  display: flex;
  width: 100%;
  height: 80px;
  padding: 15px 10px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 10px;
  border-top: 1px solid #e0e0e0;
  border-bottom: none;
  border-left: none;
  border-right: none;
  background: #fff;
  outline: none;
  color: #b5b5b5;
  font-family: Campton;
  font-size: 24px;
  font-weight: 500;
}

.create-newsletter > .card > form > .in {
  width: 97%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 20px;
}

.create-newsletter > .card > form > .in > button {
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  background: #419a2b;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  border: none;
  cursor: pointer;
  outline: none;
}

.subscriber-container {
  position: fixed;
  top: 10%;
  right: 0%;
  width: 400px;
  height: 844px;
  flex-shrink: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  display: flex;
  flex-direction: column;
  z-index: 10;
}

.subscriber-container > .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.subscriber-container > .header > .icon {
  cursor: pointer;
}

.subscriber-container > .header > h3 {
  color: #202020;
  font-family: Campton;
  font-size: 18px;
  font-weight: 700;
}

.subscriber-container > .card-container {
  width: 100%;
  min-height: 300px;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.subscriber-container > .card-container > .subscriber-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: flex;
  height: 72px;
  padding: 16px 24px;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid #eaecf0;
}

.subscriber-container > .card-container > .subscriber-card > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}

.subscriber-container > .card-container > .subscriber-card > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 500;
}

.newsletter-table {
  width: 100%;
  min-height: 400px;
  margin-top: 20px;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background: #fff;
  padding: 20px;
}

table {
  width: 100%;
}

table > thead > tr > th {
  height: 44px;
  padding: 12px 0px;
  gap: 12px;
  color: #667085;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  border-bottom: 1px solid #eaecf0;
  background: #f9fafb;
}

table > tbody > tr > td {
  color: #202020;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  height: 50px;
  padding: 16px 0px;
  border-bottom: 1px solid #eaecf0;
}

.pagination-sub {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination-sub > p {
  color: #344054;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.pagination-sub > .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
}

.pagination-sub > .btn > button {
  display: flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--Gray-700, #344054);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
}

.pagination-sub > .btn > button:disabled {
  background-color: lightgray;
  cursor: not-allowed;
}

.newsletter-table > .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
}

.newsletter-table > .header > .wrap {
  display: flex;
  align-items: center;
  gap: 8px;
}

.newsletter-table > .header > .wrap > p {
  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
}

.newsletter-table > .header > .icon {
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  display: flex;
  width: 30px;
  height: 30px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #202020;
  background: #fff;
}

.newsletter-table > .header > .icon:disabled {
  cursor: not-allowed;
  background-color: lightgray;
}

.delete-overlay-newsl {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0%;
  left: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(32, 32, 32, 0.7);
  z-index: 101;
  gap: 15px;
}

.delete-overlay-newsl > .overlay-content {
  display: flex;
  padding: 25px 50px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
  background: #fff;
}

.delete-overlay-newsl > .overlay-content > .icon {
  width: 50px;
  height: 50px;
  color: #e30613;
}

.delete-overlay-newsl > .overlay-content > h3 {
  color: #202020;
  font-family: Campton;
  font-size: 16px;
  font-weight: 600;
}

.delete-overlay-newsl > .overlay-content > p {
  width: 144px;
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 118.243%;
}

.delete-overlay-newsl > .overlay-content > .buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.delete-overlay-newsl > .overlay-content > .buttons > button:nth-child(1) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  outline: none;
  cursor: pointer;
}

.delete-overlay-newsl > .overlay-content > .buttons > button:nth-child(2) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #d0310e;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  border: none;
  outline: none;
  cursor: pointer;
}
