* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Campton;
  src: url("../../../../public/Campton-Light.ttf");
}

@font-face {
  font-family: Montserrat;
  src: url("../../../../public/Montserrat-VariableFont_wght.ttf");
}

.media-container {
  padding: 100px 20px 0px 20px;
  width: 81.9%;
  margin-left: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.media-container > .header {
  width: 100%;
  display: flex;
  padding: 20px 0px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.media-container > .header > h3 {
  color: #254a38;
  font-family: Campton;
  font-size: 24px;
  font-weight: 800;
  line-height: 83.523%;
}

.media-container > .header > button {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #254a38;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.media-container > .header > button > .icon {
  width: 20px;
  height: 20px;
}

.media-container > .years-container {
  width: 100%;
  min-height: 550px;
  display: grid;
  grid-template-columns: repeat(4, 4fr);
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 20px;
}

.media-container > .years-container > .card {
  width: 100%;
  text-decoration: none;
}

.media-container > .years-container > .card > img {
  width: 100%;
  height: 200px;
  object-fit: 100%;
}

.media-container > .years-container > .card .wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
}

.festival-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0%;
  left: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(32, 32, 32, 0.7);
  z-index: 101;
}

.festival-container > .card {
  display: flex;
  padding: 30px 50px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  border-radius: 20px;
  background: #fff;
}

.festival-container > .card > h3 {
  color: #202020;
  font-family: Campton;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
}

.festival-container > .card > form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.festival-container > .card > form > label {
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
}

.festival-container > .card > form > label > input {
  width: 500px;
  height: 44px;
  border-radius: 8px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fff;
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  padding-left: 10px;
  outline: none;
}

.festival-container > .card > form > .image-card {
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.festival-container > .card > form > .image-card > .in {
  width: 100%;
}

.festival-container > .card > form > .image-card > .in > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
}

.festival-container > .card > form > .image-card > .picker {
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  border: 1px dashed rgba(32, 32, 32, 0.7);
  background: #fff;
}

.festival-container > .card > form > .image-card > .picker > label {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.festival-container > .card > form > .image-card > .picker > .image-preview {
  width: 100%;
  height: 200px;
  object-fit: contain;
}

.festival-container > .card > form > .image-card > .picker > label > svg {
  width: 96px;
  height: 106px;
}

.festival-container > .card > form > .image-card > .picker > label > span {
  color: rgba(32, 32, 32, 0.8);
  font-family: Campton;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.festival-container > .card > form > .image-card > .picker > label > span > h3 {
  color: #0085ff;
  font-family: Campton;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
}

.festival-container > .card > form > .image-card > .picker > label > p {
  color: rgba(181, 181, 181, 0.8);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.festival-container > .card > form > .btn {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 20px;
}

.festival-container > .card > form > .btn > button:nth-child(1) {
  display: flex;
  width: 100px;
  height: 44px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #fff;
  color: rgba(32, 32, 32, 0.7);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  border: none;
  outline: none;
  cursor: pointer;
}

.festival-container > .card > form > .btn > button:nth-child(2) {
  display: flex;
  width: 100px;
  height: 44px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 7px;
  background: #254a38;
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  line-height: 25px;
  border: none;
  outline: none;
  cursor: pointer;
}

.festival-container > .card > form > .btn > button:disabled {
  background-color: #4d9a75;
  cursor: not-allowed;
}
