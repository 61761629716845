* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Campton;
  src: url("../../../../public/Campton-Light.ttf");
}

@font-face {
  font-family: Montserrat;
  src: url("../../../../public/Montserrat-VariableFont_wght.ttf");
}

.add-image-gallary {
  padding: 100px 20px 0px 20px;
  width: 81.9%;
  margin-left: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.add-image-gallary > .header {
  width: 100%;
  display: flex;
  padding: 20px 0px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.add-image-gallary > .header > .in {
  display: flex;
  align-items: center;
  gap: 10px;
}

.add-image-gallary > .header > .in > .icon {
  color: #254a38;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.add-image-gallary > .header > .in > h3 {
  color: #254a38;
  font-family: Campton;
  font-size: 24px;
  font-weight: 800;
  line-height: 83.523%;
}

.add-image-gallary > .header > button {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #254a38;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.add-image-gallary > .header > .delete-btn {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #d0310e;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.add-image-gallary > .header > button > .icon {
  width: 20px;
  height: 20px;
}

.add-image-gallary > .image-card {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 4fr);
  gap: 30px;
  margin-top: 30px;
}

.add-image-gallary > .image-card > p {
  color: #254a38;
  font-family: Campton;
  font-size: 16px;
  font-weight: 800;
  line-height: 83.523%;
}

.card-gallary > img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.imagegallary-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0%;
  left: 0%;
  background: rgba(32, 32, 32, 0.7);
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imagegallary-overlay > .card {
  min-width: 600px;
  display: flex;
  padding: 30px 50px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  border-radius: 20px;
  background: #fff;
}

.imagegallary-overlay > .card > h3 {
  color: #202020;
  font-family: Campton;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
}

.imagegallary-overlay > .card > form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.imagegallary-overlay > .card > form > .picker {
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  border: 1px dashed rgba(32, 32, 32, 0.7);
  background: #fff;
}

.imagegallary-overlay > .card > form > .picker > .prev {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 4fr);
  gap: 5px;
}

.imagegallary-overlay > .card > form > .picker > .prev > img {
  width: 100%;
  height: 100px;
  object-fit: contain;
}

.imagegallary-overlay > .card > form > .picker > label {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}
.imagegallary-overlay > .card > form > .picker.image-preview {
  width: 100%;
  height: 200px;
  object-fit: contain;
}

.imagegallary-overlay > .card > form > .picker > label > svg {
  width: 96px;
  height: 106px;
}

.imagegallary-overlay > .card > form > .picker > label > span {
  color: rgba(32, 32, 32, 0.8);
  font-family: Campton;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imagegallary-overlay > .card > form > .picker > label > span > h3 {
  color: #0085ff;
  font-family: Campton;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
}
.imagegallary-overlay > .card > form > .picker > label > p {
  color: rgba(181, 181, 181, 0.8);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.imagegallary-overlay > .card > form > .btn {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 20px;
}

.imagegallary-overlay > .card > form > .btn > button:nth-child(1) {
  display: flex;
  width: 100px;
  height: 44px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #fff;
  color: rgba(32, 32, 32, 0.7);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  border: none;
  outline: none;
  cursor: pointer;
}

.imagegallary-overlay > .card > form > .btn > button:nth-child(2) {
  display: flex;
  width: fit-content;
  height: 44px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 7px;
  background: #254a38;
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  line-height: 25px;
  border: none;
  outline: none;
  cursor: pointer;
}

.imagegallary-overlay > .card > form > .btn > button:disabled {
  background-color: #4d9a75;
  cursor: not-allowed;
}

.card-gallary {
  position: relative;
}

.card-gallary input[type="checkbox"] {
  position: absolute;
  top: 10px;
  right: 10px;
}

.delete-images {
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 101;
  width: 100%;
  height: 100%;
  background: rgba(32, 32, 32, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.delete-images > .card {
  display: flex;
  padding: 25px 50px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
  background: #fff;
}

.delete-images > .card > .icon {
  width: 50px;
  height: 50px;
  color: #d0310e;
}

.delete-images > .card > h3 {
  color: #202020;
  font-family: Campton;
  font-size: 16px;
  font-weight: 600;
}

.delete-images > .card > p {
  width: 174px;
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 118.243%;
}

.delete-images > .card > .btn {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.delete-images > .card > .btn > button:nth-child(1) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  outline: none;
}

.delete-images > .card > .btn > button:nth-child(2) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #d0310e;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  border: none;
  outline: none;
  cursor: pointer;
}

.delete-images > .card > .btn > button:disabled {
  background: #f07053;
  cursor: not-allowed;
}
