* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Campton;
  src: url("../../../../public/Campton-Light.ttf");
}

@font-face {
  font-family: Montserrat;
  src: url("../../../../public/Montserrat-VariableFont_wght.ttf");
}

.speeches-container {
  padding: 100px 20px 0px 20px;
  width: 81.9%;
  margin-left: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

 .error {
  color: rgb(228, 56, 56);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin: 40px;
  width: 100%;
}

.speeches-container > .header {
  width: 100%;
  display: flex;
  padding: 20px 0px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.speeches-container > .header > h3 {
  color: #254a38;
  font-family: Campton;
  font-size: 24px;
  font-weight: 800;
  line-height: 83.523%;
}

.speeches-container > .header > .wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.speeches-container > .header > .wrap > span {
  width: 340px;
  height: 44px;
  display: flex;
  padding: 10px 14px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid rgba(32, 32, 32, 0.7);
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f4ebff;
}

.speeches-container > .header > .wrap > span > .icon {
  width: 20px;
  height: 20px;
}

.speeches-container > .header > .wrap > span > input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  color: #101828;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.speeches-container > .header > .wrap > .fill {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--White, #fff);
  color: var(--Gray-700, #344054);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.speeches-container > .header > .wrap > button {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #254a38;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.speeches-container > .header > .wrap > button > .icon {
  width: 20px;
  height: 20px;
}

.speeches-container > .news-card {
  width: 100%;
  min-height: 550px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin-top: 20px;
}

.speeches-container > .news-card > .wrapper {
  display: grid;
  grid-template-columns: repeat(4, 4fr);
  gap: 24px;
}

.speeches-container > .news-card > .wrapper > .news-item {
  width: 100%;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 36px;
  flex: 1 0 0;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background: #fff;
}

.speeches-container > .news-card > .wrapper > .news-item > h2 {
  color: #202020;
  font-family: Campton;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.36px;
}

.speeches-container > .news-card > .wrapper > .news-item > .inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.speeches-container > .news-card > .wrapper > .news-item > .inner > p {
  color: rgba(32, 32, 32, 0.5);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 132.1%;
}

.speeches-container > .news-card > .wrapper > .news-item > .inner > .action {
  display: flex;
  gap: 10px;
}

.speeches-container
  > .news-card
  > .wrapper
  > .news-item
  > .inner
  > .action
  > button:nth-child(1) {
  cursor: pointer;
  padding: 3px 5px;
  background-color: transparent;
  color: #202020;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  border: none;
  outline: none;
  display: flex;
  gap: 3px;
  align-items: center;
}
.speeches-container
  > .news-card
  > .wrapper
  > .news-item
  > .inner
  > .action
  > button:nth-child(2) {
  cursor: pointer;
  padding: 3px 5px;
  background-color: transparent;
  color: #C1061A;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
}

.speeches-container > .news-card > .pagination {
  width: 100%;
  border-top: 1px solid #eaecf0;
  display: flex;
  height: 64px;
  padding: 12px 24px 16px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.speeches-container > .news-card > .pagination > p {
  color: var(--Gray-700, #344054);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.speeches-container > .news-card > .pagination > .btn {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.speeches-container > .news-card > .pagination > .btn > button {
  display: flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--Gray-700, #344054);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
}

.speeches-container > .news-card > .pagination > .btn > button:disabled {
  background-color: lightgray;
  cursor: not-allowed;
}

.delete-news-con,
.update-news-con {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0%;
  left: 0%;
  background: rgba(32, 32, 32, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 101;
}

.delete-news-con > .card {
  width: 350px;
  display: flex;
  padding: 25px 50px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
  background: #fff;
}

.delete-news-con > .card > h3 {
  color: #202020;
  font-family: Campton;
  font-size: 16px;
  font-weight: 600;
}

.delete-news-con > .card > p {
  width: 144px;
  height: 27px;
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 118.243%;
}

.delete-news-con > .card > .btn {
  display: flex;
  gap: 10px;
}

.delete-news-con > .card > .btn > button {
  cursor: not-allowed;
  background-color: lightgray;
}

.delete-news-con > .card > .btn > button:nth-child(1) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  cursor: pointer;
  font-weight: 700;
  outline: none;
}

.delete-news-con > .card > .btn > button:nth-child(2) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #d0310e;
  border: none;
  color: white;
  font-family: Montserrat;
  font-size: 14px;
  cursor: pointer;
  font-weight: 700;
  outline: none;
}

.update-news-cons {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0%;
  left: 0%;
  background: rgba(32, 32, 32, 0.7);
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 101;
  padding: 40px;
  overflow: scroll;
}

.update-news-cons::-webkit-scrollbar {
  display: none;
}


.update-news-cons {
  -ms-overflow-style: none;  
  scrollbar-width: none;  
}

.update-news-cons > .card {
  width: 60%;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.update-news-cons > .card > form > img {
  width: 50%;
  height: 250px;
  object-fit: contain;
  margin-top: 10px;
}

.update-news-cons > .card > form > input {
  width: 100%;
  display: flex;
  height: 40px;
  padding: 15px 10px;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  background: #fff;
  outline: none;
  border-bottom: none;
}

.update-news-cons > .card > form > .btn {
  width: 100%;
  margin: 50px 0px 20px 0px;
  padding: 30px 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  gap: 15px;
}

.update-news-cons > .card > form > .btn > button:nth-child(1) {
  display: flex;
  width: 100px;
  height: 44px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #fff;
  color: rgba(32, 32, 32, 0.7);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  border: 1px solid #101828;
  cursor: pointer;
}

.update-news-cons > .card > form > .btn > button:nth-child(2) {
  display: flex;
  width: 100px;
  height: 44px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 7px;
  background: #254a38;
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  border: none;
  outline: none;
  cursor: pointer;
}
