* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Campton";
  src: url("../../../public/Campton-Light.ttf");
}
@font-face {
  font-family: "Colgent";
  src: url("../../../public/Colgent-Italic.ttf");
}

@font-face {
  font-family: "Montserrat";
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}



input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.one-time-verification {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.one-time-verification > .banner {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(32, 32, 32, 0.5);
  background: #254a38;
}

.one-time-verification > .wrapper {
  /* width: 30%; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
}

.one-time-verification > .wrapper > h3 {
  color: #202020;
  font-family: Campton;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
}
.one-time-verification > .wrapper > p {
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin-top: 15px;
}

.one-time-verification > .wrapper > .btn {
  width: 100%;
  margin: 10px 0px;
}

.one-time-verification > .wrapper > .btn > button {
  padding: 8px 15px;
  background: #254a38;
  border: none;
  cursor: pointer;
  outline: none;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
}


.one-time-verification > .wrapper > .btn > button:disabled {
  cursor: not-allowed;
  background-color: #7f978c;
}



.one-time-verification > .wrapper > form {
  width: 100%;
  display: flex;
  justify-self: center;
  align-items: center;
  gap: 20px;
  flex-direction: column;
  margin-top: 40px;
}

.one-time-verification > .wrapper > form > .token-inputs {
  width: 100%;
  display: flex;
  gap: 20px;
}

.one-time-verification > .wrapper > form > .token-inputs > span {
  width: 100%;
  display: flex;
}

.one-time-verification > .wrapper > form > .token-inputs > span > input {
  height: 115px;
  width: 115px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  background: #fff;
  font-family: Montserrat;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
}

.one-time-verification > .wrapper > form > button {
  width: 100%;
  height: 55px;
  border-radius: 30px;
  font-family: Montserrat;
  border: none;
  outline: none;
  color: white;
  background: #254a38;
  cursor: pointer;
}

.one-time-verification > .wrapper > form > button:disabled {
  cursor: not-allowed;
  background-color: #7f978c;
}

.one-time-verification > .wrapper > form > P {
  font-family: Montserrat;
}
