* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Campton";
  src: url("../../../public/Campton-Light.ttf");
}
@font-face {
  font-family: "Colgent";
  src: url("../../../public/Colgent-Italic.ttf");
}

@font-face {
  font-family: "Montserrat";
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}


input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.auth-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-container > .logo {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid rgba(32, 32, 32, 0.5);
  background: #254a38;
  margin-bottom: 100px;
}

.auth-container > .login-form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}

.auth-container > .login-form > p {
  color: #202020;
  font-family: Campton;
  font-size: 26px;
  font-weight: 600;
}

.auth-container > .login-form > form {
  width: 540px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}

.auth-container > .login-form > form > label {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.auth-container > .login-form > form > label > input {
  width: 540px;
  height: 50px;
  border-radius: 8px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fff;
  padding-left: 15px;
  outline: none;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.auth-container > .login-form > form > label > span {
  width: 540px;
  height: 50px;
  border-radius: 8px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fff;
  padding: 0px 15px;
  outline: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.auth-container > .login-form > form > label > a {
  text-decoration: none;
  color: #d0310e;
  font-family: Montserrat;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  width: fit-content;
}

.auth-container > .login-form > form > label > span > input {
  width: 100%;
  border: none;
  outline: none;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.auth-container > .login-form > form > label > span > .icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.auth-container > .login-form > form > button {
  display: flex;
  width: 100%;
  height: 50px;
  border-radius: 80px;
background: #254A38;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  outline: none;
  cursor: pointer;
  border: none;
}

.auth-container > .login-form > form > button:disabled {
    background-color: #79a38f;
    cursor: not-allowed;
}

.auth-container > .login-form > form > .error {
    color: #d0310e;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
}
