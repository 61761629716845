* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Campton";
  src: url("../../public/Campton-Light.ttf");
}
@font-face {
  font-family: "Colgent";
  src: url("../../public/Colgent-Italic.ttf");
}

@font-face {
  font-family: "Montserrat";
  src: url("../../public/Montserrat-VariableFont_wght.ttf");
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.navbar-container {
  width: 260px;
  height: 100vh;
  display: flex;
  background: #254a38;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px 20px 30px 20px;
  position: fixed;
  overflow: auto;
  z-index: 100;
}

.navbar-container > .nav-links > .logo {
  padding: 20px 40px;
  text-decoration: none;
  margin-bottom: 20px;
  background-color: transparent;
  outline: none;
  border: none;
}

.admin_header-container {
  width: 81.9%;
  background-color: white;
  position: fixed;
  right: 0%;
  top: 0%;
  margin-left: 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  height: 80px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
  z-index: 100;
}

.admin_header-container > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 83.523%;
}

.admin_header-container > p > span {
  color: #254a38;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  line-height: 83.523%;
}

.admin_header-container > .side {
  display: flex;
  width: 45px;
  height: 45px;
  padding: 9.5px 9px 9.5px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  border: 1px solid #e0e0e0;
}

.navbar-container > .nav-links {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.navbar-container > .nav-links > a.active {
  background-color: white;
  color: #254a38;
}

.navbar-container > .nav-links > a.active > .icon {
  color: #254a38;
}


.navbar-container > .nav-links > a {
  display: flex;
  align-items: center;
  width: 220px;
  height: 50px;
  padding: 10px 81px 10px 10px;
  gap: 10px;
  border-radius: 10px;
  color: white;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;
}

.navbar-container > .nav-links > a > .icon {
  width: 24px;
  height: 24px;
  color: white;
 
}

.navbar-container > button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #fbfbfb;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  padding: 10px;
  width: fit-content;
  gap: 10px;
  align-items: center;
}

.navbar-container > button > .icon {
  width: 24px;
  height: 24px;
}
