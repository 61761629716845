* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Campton;
  src: url("../../../../public/Campton-Light.ttf");
}

@font-face {
  font-family: Montserrat;
  src: url("../../../../public/Montserrat-VariableFont_wght.ttf");
}

.create-news-container {
  padding: 100px 20px 0px 20px;
  width: 81.9%;
  margin-left: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.create-news-container > .header {
  width: 100%;
  display: flex;
  padding: 20px 0px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.create-news-container > .header > .wrap {
  display: flex;
  align-items: center;
  gap: 10px;
}

.create-news-container > .header > .wrap > .icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  color: #254a38;
}

.create-news-container > .header > .wrap > h3 {
  color: #254a38;
  font-family: Campton;
  font-size: 24px;
  font-weight: 800;
  line-height: 83.523%;
}

.create-news-container > .header > button {
  display: flex;
  padding: 10px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 66px;
  background: #2b9a5f;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  border: none;
  outline: none;
  cursor: pointer;
}

.create-news-container > .create-news {
  width: 100%;
  padding: 20px 0px;

}

.create-news-container > .create-news > form {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: Montserrat;
}

.create-news-container > .create-news > form > img {
    width: 400px;
    height: 400px;
    object-fit: contain;
    margin: auto;
}

.create-news-container > .create-news > form > input {
  width: 100%;
  display: flex;
  height: 40px;
  padding: 15px 10px;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  background: #fff;
  outline: none;
  border-bottom: none;
}



.create-news-container > .create-news > form > .wrap {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; 
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-top: none;
}

.create-news-container > .create-news > form > .wrap > .logo {
  width: 24px;
  height: 24px;
  margin: 10px;
}

.create-news-container > .create-news > form > .wrap > button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: rgba(32, 32, 32, 0.80);
font-family: Montserrat;
font-size: 16px;
font-weight: 400;
line-height: 153.6%; 
}

.new-overlay-container {
  position: fixed;
  top: 0%;
  width: 100%;
  height: 100%;
  background: rgba(32, 32, 32, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
}

.new-overlay-container > .card {
  display: flex;
  width: 600px;
  height: 377px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.new-overlay-container > .card > h3 {
  display: flex;
  width: 100%;
  padding: 20px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #e0e0e0;
  color: #202020;
  font-family: Campton;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  margin-bottom: 30px;
}

.new-overlay-container > .card > label {
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
}

.new-overlay-container > .card > label > input {
  width: 100%;
  height: 44px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fff;
  color: #202020;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  padding: 0px 15px;
  outline: none;
}

.new-overlay-container > .card > .btn {
  margin-top: 30px;
  width: 500px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
  gap: 20px;
}

.new-overlay-container > .card > .btn > button:nth-child(1) {
  color: rgba(32, 32, 32, 0.7);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  display: flex;
  width: 100px;
  height: 44px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

.new-overlay-container > .card > .btn > button:nth-child(2) {
  display: flex;
  width: 100px;
  height: 44px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 45px;
  background: #254a38;
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  border: none;
  outline: none;
  cursor: pointer;
}

.new-overlay-container > .card > .btn > button:nth-child(2):disabled {
  background-color: #8be7b6;
}
