* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Campton;
  src: url("../../../../public/Campton-Light.ttf");
}

@font-face {
  font-family: Montserrat;
  src: url("../../../../public/Montserrat-VariableFont_wght.ttf");
}

.messages-container {
  padding: 100px 20px 0px 20px;
  width: 81.9%;
  margin-left: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.messages-container > .header {
  width: 100%;
  display: flex;
  padding: 20px 0px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.messages-container > .header > h3 {
  color: #254a38;
  font-family: Campton;
  font-size: 24px;
  font-weight: 800;
  line-height: 83.523%;
}

.messages-container > .header > .wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.messages-container > .header > .wrap > .fill {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--White, #fff);
  color: var(--Gray-700, #344054);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.messages-card {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background: #fff;
  padding: 20px;
}

.messages-card > .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.messages-card > .header > span {
  display: flex;
  gap: 10px;
}

.messages-card > .header > span > p {
  color: #000;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
}

.messages-card > .header > button {
  display: flex;
  width: 30px;
  height: 30px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #202020;
  background: #fff;
  cursor: pointer;
}

.messages-card > .header > button > .icon {
  width: 18px;
  height: 18px;
  flex-shrink: 0;
}

.messages-card > .incoming-message {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.messages-card > .incoming-message > .wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.messages-card > .incoming-message > .wrapper > .message-item {
  width: 97%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.messages-card > .incoming-message > .wrapper > .message-item > p {
  display: flex;
  padding: 15px;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  border-bottom: 1px solid #e0e0e0;
  background: #fff;
  color: #202020;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  width: 265px;
}

.messages-card > .incoming-message > .wrapper > .message-item > button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.pagination-msg {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination-msg > p {
  color: #344054;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.pagination-msg > .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
}

.pagination-msg > .btn > button {
  display: flex;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--Gray-700, #344054);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
}

.pagination-msg > .btn > button:disabled {
  background-color: lightgray;
  cursor: not-allowed;
}

.delete-msg-overlay,
.view-msg-overlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 101;
  background: rgba(32, 32, 32, 0.7);
}

.delete-msg-overlay > .card {
  display: flex;
  padding: 25px 50px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
  background: #fff;
}

.delete-msg-overlay > .card > h3 {
  color: #202020;
  font-family: Campton;
  font-size: 16px;
  font-weight: 600;
}

.delete-msg-overlay > .card > p {
  width: 244px;
  color: #202020;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 118.243%;
}

.delete-msg-overlay > .card > .btn {
  display: flex;
  gap: 10px;
}

.delete-msg-overlay > .card > .btn > button {
  cursor: not-allowed;
  background-color: lightgray;
}

.delete-msg-overlay > .card > .btn > button:nth-child(1) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fefefe;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  cursor: pointer;
  font-weight: 700;
  outline: none;
}

.delete-msg-overlay > .card > .btn > button:nth-child(2) {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #d0310e;
  border: none;
  color: white;
  font-family: Montserrat;
  font-size: 14px;
  cursor: pointer;
  font-weight: 700;
  outline: none;
}

.view-msg-overlay > .card {
  width: 850px;
  min-height: 300px;
  flex-shrink: 0;
  background: #fff;
}

.view-msg-overlay > .card > .header {
  display: flex;
  padding: 20px 30px;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
}

.view-msg-overlay > .card > .header > .in {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.view-msg-overlay > .card > .header > .in > h3 {
  color: #202020;
  font-family: Campton;
  font-size: 16px;
  font-weight: 600;
}

.view-msg-overlay > .card > .header > .in > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  text-decoration-line: underline;
}

.view-msg-overlay > .card > .header > p {
  color: #202020;
  text-align: right;
  font-family: Montserrat;
  font-size: 14px;
  width: fit-content;
  font-weight: 600;
}

.view-msg-overlay > .card > .inner {
  padding: 20px;
}

.view-msg-overlay > .card > .inner > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.view-msg-overlay > .card > .btn {
  margin: 100px 20px 20px 20px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.view-msg-overlay > .card > .btn > button {
  display: inline-flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  background: #419a2b;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  outline: none;
  border: none;
  cursor: pointer;
}
