* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Campton";
  src: url("../../../public/Campton-Light.ttf");
}
@font-face {
  font-family: "Colgent";
  src: url("../../../public/Colgent-Italic.ttf");
}

@font-face {
  font-family: "Montserrat";
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}


input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.request-pass-reset {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.request-pass-reset > .logo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  border-bottom: 1px solid rgba(32, 32, 32, 0.5);
  background: #254a38;
  margin-bottom: 100px;
}

.request-pass-reset > .reset-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.request-pass-reset > .reset-container > h3 {
  color: #202020;
  font-family: Campton;
  font-size: 26px;
  font-weight: 600;
}

.request-pass-reset > .reset-container > form {
  width: 540px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.request-pass-reset > .reset-container > form > label {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.request-pass-reset > .reset-container > form > label > input {
  width: 100%;
  height: 50px;
  border-radius: 8px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fff;
  outline: none;
  padding-left: 15px;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.request-pass-reset > .reset-container > form > button {
  width: 100%;
  display: flex;
  height: 50px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 80px;
  background: #254a38;
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  outline: none;
  cursor: pointer;
  border: none;
}

.request-pass-reset > .reset-container > form > button:disabled{
    background-color: #79a38f;
    cursor: not-allowed;
}

.request-pass-reset > .reset-container > form > .error {
  color: #d0310e;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}