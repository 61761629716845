* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Campton;
  src: url("../../../../public/Campton-Light.ttf");
}

@font-face {
  font-family: Montserrat;
  src: url("../../../../public/Montserrat-VariableFont_wght.ttf");
}

.settings-container {
  padding: 100px 20px 0px 20px;
  width: 81.9%;
  margin-left: 260px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.settings-container > .header {
  width: 100%;
  display: flex;
  padding: 20px 0px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(32, 32, 32, 0.4);
}

.settings-container > .header > h3 {
  color: #254a38;
  font-family: Campton;
  font-size: 24px;
  font-weight: 800;
  line-height: 83.523%;
}

.settings-container > .data-card {
  width: 885px;
  display: flex;
  padding: 30px;
  flex-direction: column;
  flex-shrink: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  margin-top: 20px;
}

.settings-container > .data-card > span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.settings-container > .data-card > span > p {
  height: 44px;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
}

.settings-container > .data-card > span > a {
  color: #3156db;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  text-decoration-line: underline;
}
